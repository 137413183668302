.faq-question-wrap {
    /* border-style: solid; */
    /* border-width: 2px 2px 0; */
    /* border-color: #000; */
    border-bottom: 1px solid;
    margin-top: -27px;
}

.faq-question-wrap:last-child {
    /* border-top-width: 0;
    border-bottom-width: 2px; */
    /* border-bottom: none; */
}

.faq-question-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* border-top: 1px #000; */
    /* border-bottom: 1px solid #000; */
    color: #191b22;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    margin-bottom: -5px;
}

.question-title {
    /* border-top: 0 solid #000; */
    font-size: 24px;
    font-weight: 700
}

.faq-content {
    overflow: hidden;
    margin-top: -38px;
    /* border-bottom: 1px solid #000; */
    /* padding-bottom: 30px; */
    text-align: justify;
}

.faq-paragraph {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 15px;
    /* border-bottom: 0 solid #000; */
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: -.25px
}
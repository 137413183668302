.flex-active {
    display: flex;
    align-items: center;
}
.nav-logo-name {
    margin-left: 8px;
    /* line-height: 40px; */
    /* font-weight: bold; */
    font-weight: 600;
    color: black;
    /* font-size: 26px; */
    font-family: ui-serif;
}

.inner-btn-section {
    display: flex;
    align-items: center;
}
.coming-soon {
    background: #00091B;
    color: #fff;
    height: 100vh;
}
  
  
  @keyframes fadeIn {
    from {top: 20%; opacity: 0;}
    to {top: 100; opacity: 1;}
    
  }
  
  @-webkit-keyframes fadeIn {
    from {top: 20%; opacity: 0;}
    to {top: 100; opacity: 1;}
    
  }
  
  .coming-soon-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    animation: fadeIn 1000ms ease;
    -webkit-animation: fadeIn 1000ms ease;
    
  }
  
  .coming-soon-title {
    text-align: center;
    color: #fff;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
  }
  
  .dot {
    color: #4FEBFE;
  }
  
  .coming-soon-subtitle {
    color: #fff;
    text-align: center;
    /* margin: 18px; */
    font-family: 'Muli', sans-serif;
    font-weight: normal;
    
  }

  @media only screen and (max-width: 600px) {
    .coming-soon-title {
        font-size: 35px;
    }
  }
  
  .coming-soon-icons {
    display: flex;
    justify-content: center;
  }
  
  .coming-soon-icons i {
    color: #00091B;
    background: #fff;
    height: 15px;
    width: 15px;
    padding: 13px;
    margin: 0 10px;
    border-radius: 50px;
    border: 2px solid #fff;
    transition: all 200ms ease;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .coming-soon-icons i:hover, .coming-soon-icons i:active {
    color: #fff;
    background: none;
    cursor: pointer !important;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    text-decoration: none;
    
  }